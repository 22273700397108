
import { Component, Prop, Vue } from "vue-property-decorator";
import XCard from "./SimpleCard.vue";
import XList from "./hoc/SimpleList.vue";

@Component({
  components: { XCard, XList },
})
class XCardWithList extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ required: true }) readonly items!: [];

  @Prop({ default: false }) readonly splitCardSection!: boolean;

  // XList props
  @Prop({ default: "" }) readonly listModClass!: string;
  @Prop({ default: "" }) readonly listKeySize!: string;
}

export default XCardWithList;
