
  import {Component, Vue, Prop} from "vue-property-decorator";
  import SimpleButton              from '@/components/SimpleButton.vue'

  @Component({
    components: {
      SimpleButton
    },
  })

  class DropZone extends Vue {

    @Prop({ default: true })

    private enabled!: boolean;
    private hightlight = false;

    openFileSelection(): void {
      if (!this.enabled) return;
      (<HTMLInputElement>this.$refs.fileInput).value = '';
      (<HTMLInputElement>this.$refs.fileInput).click();
    }
    onDragOver(event: DragEvent): void {
      if (!this.enabled) return;
      event.preventDefault();
      this.hightlight = true;
    }
    onDragLeave(event: DragEvent): void {
      this.hightlight = false;
    }
    onDrop(event: DragEvent): void {
      if (!this.enabled) return;
      event.preventDefault();
      const files = event.dataTransfer ? event.dataTransfer.files : [];
      let result: File[] = [];
      for (var i = 0; i < files.length; i++) {
        //@ts-ignore
          result.push(<File>files.item(i));

      }
      this.$emit('filesAdded', result);
      this.hightlight = false;
    }
    onFileChanged(event: any) {
      let files: FileList = event.target.files;
      let result: File[] = [];
      for (var i = 0; i < files.length; i++) {
        result.push(<File>files.item(i));
      }
      this.$emit('filesAdded', result);
    }

  }

  export default DropZone

