
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { prepareMoney } from "@/assets/scripts/utils";
import AccountDropdown from "@/components/for-page-grid/AccountDropdown.vue";
import ContractDropdown from "@/components/for-page-grid/ContractDropdown.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: {
    AccountDropdown,
    ContractDropdown,
  },
  computed: {
    ...mapState({ currentContract: "contract" }),
    ...mapGetters("contract", {
      useContract: "useContract",
      contractBalance: "balance",
      contractId: "id"
    }),
    ...mapGetters("contracts", {
      sortedContractsByOrganization: "sortedContractsByOrganization",
    }),
    ...mapGetters("appeals", {
      appealsList: "appealsList",
    }),
    ...mapGetters({
      unreadNotificationsCount: "notifications/unreadNotificationsCount",
    }),
    hasNotifications() {
      return this.unreadNotificationsCount > 0;
    }
  },
  filters: {
    prepareMoney(value: number) {
      return prepareMoney(value);
    },
  },
})
class AppBar extends Mixins(AppApiMixin) {
  baseGapClass = "mr-7";
  dropdownNudgeBottom = 60;

  mounted() {

  }

  openNotifications() {
    const routeName = "notificationsByContract";
    this.$route.name !== routeName && this.contractId > 0 && this.$router.push({ name: routeName, params: { contractId: this.contractId } });
  }
}

export default AppBar;
