
import {Component, Mixins, Prop} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import XDialog from "../SimpleDialog.vue";
import AppApiMixin from "../mixins/AppApi.vue";
import {countersNewValueForm} from "@/assets/scripts/form-fields/main";
import ModalDialogForIndication from "./ModalDialogForIndication.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import { Indication } from "@/models/indications";
import { mapState } from "vuex";
import {formatDate} from "@/lib/date";

const { values, attrs, validations, errors } = countersNewValueForm;

@Component({
  components: {
    XForm,
    XButton,
    XNotice,
    XDialog,
    ModalDialogForIndication
  },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapState({
      accountingPointsPU: "accountingPoints",
    }),
  },
  validations,
})
class CountersNewValueForm extends Mixins(XForm, AppApiMixin) {
  @Prop({ required: true }) readonly rate!: number;
  @Prop({ required: true }) readonly rateFloat!: number;
  @Prop({ required: true }) readonly meteringPointId!: number;
  @Prop({ required: true }) readonly counterId!: number;
  @Prop({ required: true }) readonly zones!: number;
  @Prop({ required: true }) readonly disabled!: boolean;

  values = { ...values };

  invalidRate = [ true, true, true ];
  rateFlag = [ false, false, false ];

  useSuccessAlert = false;
  successMessage = "Показания успешно переданы на обработку";

  get zonesNames() {
    return this.indications.map((indication) => {
      return indication.зонатарифа;
    });
  }

  get indications() {
    return this.accountingPointsPU.accountingPointsPU.filter((item) => {
      return item.счетчикид == this.counterId;
    });
  }

  showField(fieldName) {
    return this.indications.hasOwnProperty(fieldName);
  }

  indication(fieldName) {
    return this.indications[fieldName].предпок;
  }

  zone(fieldName) {
    return this.indications[fieldName].зонатарифа;
  }

  indicationDate(fieldName) {
    return formatDate(this.indications[fieldName].предпокдата, "full")
  }

  getMask(str: string) {
    let r = "".padStart(this.rate, str);
    let rf = this.rateFloat > 0 ? "." + "".padStart(this.rateFloat, str) : "";
    return "" + r + rf;
  }

  /**
   * Отображаем ли поле
   * @param fieldName - Название поля
   */
  fieldRequired(fieldName: number) {
    return fieldName < this.zones
  }

  /**
   * Некорректный расход
   */
  get invalidRateFlag() {
    return this.invalidRate.filter((item, key) => item == true && key < this.zones).length > 0;
  }

  /**
   * Валидатор показания
   */
  get rateValidator() {
    const { rate, rateFloat } = this;

    const pattern = {
      template: `^\\d{1,${rate}}*$`,
      replacer: rateFloat > 0 ? `([.,]\\d{1,${rateFloat}})?` : "",
    };

    return new RegExp(pattern.template.replace("*", pattern.replacer));
  }

  /**
   * Валидация показания
   * @param fieldName - индекс поля
   * @param readings - значение
   */
  public validateRate(fieldName: number, readings: string) {
    this.$set(this.rateFlag, fieldName, false);

    if (!readings) {
      // не указано значение, форма невалидна, но не взводим ошибку на поле ввода
      this.$set(this.invalidRate, fieldName, true);
      return true;
    }

    if (!this.rateValidator.test(readings)) {
      // поле невалидно
      this.$set(this.invalidRate, fieldName, true);
      return false;
    }

    let currentIndication = readings;
    currentIndication = currentIndication.replace(/,/, '.');
    const readingsAsNumber = parseFloat(currentIndication);

    if (this.indication(fieldName) > readingsAsNumber && readingsAsNumber > 0) {
      // показание меньше предыдущего
      this.$set(this.rateFlag, fieldName, true);
    }

    this.$set(this.invalidRate, fieldName, false);

    this.submitError = "";

    return true;
  }

  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitError = "";

  show = false;

  showDialog = false; // подтверждение ввода

  twisting = 0; // флаг перекрутки

  /**
   * Закрыть диалог
   */
  public closeAllDialogs() {
    this.show = false;
    this.showDialog = false;
    this.useSubmitButtonLoading = false;
  }

  resetData() {
    // this.$refs.simpleForm.reset();
    // сброс значений показаний
    let { values } = this;
    Object.keys(values).forEach(function(key, index) {
      values[key] = "";
    });
    this.$v.$reset();
  }

  showSuccessInfo() {
    this.useSuccessAlert = true;
  }

  /**
   * Подготовка отправки формы
   */
  submitForm() {
    this.submitError = "";
    this.useSubmitButtonLoading = true;

    // проверка взведенных флагов показаний по перекрутке
    this.twisting = 0;
    if (this.rateFlag.filter((item, key) => item == true && key < this.zones).length > 0) {
      this.show = true;
    } else {
      this.show = false;
      this.showDialog = true;
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  sendDataPrepare() {

  }

  /**
   * Отправка формы
   */
  sendData() {
    const values = this.getValues();

    let indications: Array<Indication> = [];
    for (let i = 0; i < this.zones; i++) {
      const currentIndication = values[i].replace(/,/, '.');
      const readingsAsNumber = parseFloat(currentIndication);
      indications.push({
        rate: i,
        readings: readingsAsNumber,
        twisting: this.twisting,
      });
    }

    this.sendCountersNewValue(
      this.meteringPointId,
      this.counterId,
      indications
    ).then(() => {
      this.resetData();
      this.showSuccessInfo();
      // перечитываем показания для отрисовки
      this.fetchAccountingPointsPU(this.contractId);
    })
    .catch(() => {
      this.closeAllDialogs();
      this.resetData();
    })
    .finally(() => {
      this.useSubmitButtonLoading = false;
    });
  }
}

export default CountersNewValueForm;
