
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { RouteRecordPublic } from "vue-router";
import { FormFieldAttrs, FormFieldValues } from "@/models/form";
import SimpleButton from "./SimpleButton.vue";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: { SimpleButton },
})
class SimpleForm extends Vue {
  [x: string]: any;

  @Prop({ default: "" }) readonly legend!: string;

  @Prop({ default: () => ({}) }) readonly fieldAttrs!: FormFieldAttrs;

  @Prop({ default: false }) readonly submitButtonState!: boolean;
  @Prop({ default: false }) readonly submitButtonLoading!: boolean;
  @Prop({ default: "submit" }) readonly submitButtonText!: string;
  @Prop({ default: "100%" }) readonly submitButtonWidth!: string;
  @Prop({ default: false }) readonly submitButtonOutlined!: boolean;
  @Prop({ default: "" }) readonly submitErrorText!: string;
  @Prop({ default: "" }) readonly submitSuccessText!: string;

  $refs!:{
    recaptcha: VueRecaptcha;
    simpleForm: HTMLFormElement;
  };

  fieldProps = {
    noResizeTextarea: true,
    outlined: true,
    singleLine: true,
    rows: { min: 2 },
  };
  values: FormFieldValues = {};

  /**
   не всегда есть метки у полей, при отсутствии берем имена
   * @param label - Метка поля ввода.
   * @param fieldName - Название поля ввода.
   */
  getKey(label, fieldName) {
    return label !== "" ? label : fieldName;
  }

  /**
   * Возвращает объект, ключами которого являются названия полей ввода, а
   * значениями - объекты, содержащие их html-атрибуты. Используется
   * для извлечения полей ввода, соответствующих текстовому типу.
   * @returns {FormFieldAttrs}
   */
  get textFields(): FormFieldAttrs {
    const types = [
      "currency",
      "email",
      "number",
      "password",
      "tel",
      "text",
      "textarea",
      "url",
      "select",
      "file",
      "time"
    ];
    const names = Object.keys(this.fieldAttrs);
    const allAttrs: FormFieldAttrs = this.fieldAttrs;
    const newAttrs: FormFieldAttrs = {};

    return names.reduce((acc, fieldName) => {
      const { type = "" } = allAttrs[fieldName];

      if (types.includes(type)) acc[fieldName] = allAttrs[fieldName];
      return acc;
    }, newAttrs);
  }

  @Emit("click")
  emitSubmitEvt(payload?: any): void {
    this.$emit("submit", payload);
  }

  @Emit()
  emitShowNoticeEvt(payload?: any): void {
    this.$emit("show-notice", payload);
  }

  /**
   * Возвращает строку или массив строк, каждая из которых представляет
   * сообщение об ошибке валидации поля ввода. Используется для извлечения
   * указанных строк из объекта computed Vue-компонента.
   * @param fieldName - Название поля ввода.
   * @returns {string | Array<string>}
   */
  getErrors(fieldName: string): string | string[] {
    return this[`${fieldName}Errors`];
  }

  /**
   * Возвращает объект, ключами которого являются названия полей ввода, а
   * значениями - значения данных полей в момент вызова функции. Используется
   * для извлечения указанных значений из объекта data Vue-компонента. Вызов
   * необходимо производить непосредственно из компонента формы - подкласса
   * SimpleForm.
   * @returns {FormFieldValues}
   */
  getValues(): FormFieldValues {
    const names = Object.keys(this.values);
    const values: FormFieldValues = {};

    return names.reduce(
      (acc, fieldName) => ({
        ...acc,
        [fieldName]: this.values[fieldName],
      }),
      values
    );
  }

  /**
   * Сбрасывает значения полей ввода в исходное состояние, присваивая каждому из
   * них в качестве значения пустую строку. Вызов необходимо производить
   * непосредственно из компонента формы - подкласса SimpleForm.
   */
  resetValues(): void {
    const names = Object.keys(this.values);

    names.forEach((fieldName) => (this.values[fieldName] = ""));
    this.$v.$reset();
  }

  /**
   * Перенаправляет пользователя на другую страницу, сбрасывая значения полей
   * ввода в исходное состояние.
   * @param pageName - Название страницы (маршрута).
   */
  redirectToPage(pageName: string): void {
    const allRoutes: RouteRecordPublic[] = this.$router.getRoutes();
    const hasRoute = allRoutes.some((route) => route.name === pageName);

    if (hasRoute) {
      this.resetValues();
      this.$router.push({ name: pageName });
    }
  }

  resetFormRefs(){
    this.$refs.simpleForm.reset();
  }
}

export default SimpleForm;
