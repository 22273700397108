var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"slider"},[_c('div',{staticClass:"split-container box box_shadow_lighten-1",class:_vm.containerModClass},[_c('div',{ref:"splitContainerWrapper",staticClass:"slider__wrapper d-flex flex-column"},[_c('div',{staticClass:"split-container__header pa-4"},[(!_vm.showPreloader)?[_c('span',{staticClass:"font-weight-medium text-h5"},[_vm._v(_vm._s(_vm.title))]),_c('span',{class:[
                'font-weight-regular',
                'secondary--text',
                'text--lighten-1',
                'text-h6',
              ]},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]:_vm._e(),_c('x-button',{staticClass:"split-container__close-button",attrs:{"color":"background","icon":"","height":"auto","width":"auto"},on:{"click":function($event){return _vm.$emit('update:show', false)}}},[_c('v-icon',{staticClass:"icon icon_size_lg mr-0"},[_vm._v("mdi-close")])],1)],2),_c('div',{staticClass:"split-container__main flex-grow-1",class:[
            _vm.boxModClass,
            _vm.showPreloader && 'd-flex',
          ]},[(_vm.showPreloader)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":48}}):_vm._t("default")],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }