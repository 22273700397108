var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-overlay',{attrs:{"value":_vm.show,"color":"overlay","opacity":"0.8","z-index":"6"}},[_c('v-card',{staticClass:"card",attrs:{"light":"","rounded":"lg","width":"550"}},[_c('v-row',{class:[
        'font-weight-medium',
        'justify-space-between',
        'secondary--text',
        'text-h5',
        'pa-6',
      ]},[_c('span',[_vm._t("title")],2),_c('x-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.useDisagreeButtonAsIcon),expression:"useDisagreeButtonAsIcon"}],attrs:{"color":"background","icon":"","height":"auto","width":"auto"},on:{"click":function($event){return _vm.propagateCustomEvt('disagree')}}},[_c('v-icon',{staticClass:"mr-0"},[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"card__main d-block pb-6"},[_vm._t("default")],2),_c('v-row',{staticClass:"background lighten-3 justify-end px-6 py-4"},[_c('span',{staticClass:"align-self-center flex-grow-1 error--text mr-2"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('x-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.useDisagreeButtonAsIcon),expression:"!useDisagreeButtonAsIcon"}],staticClass:"mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.propagateCustomEvt('disagree')}}},[_vm._t("disagreeButton",function(){return [_vm._v("Нет")]})],2),_c('x-button',{attrs:{"disabled":_vm.agreeButtonState,"loading":_vm.agreeButtonLoading},on:{"click":function($event){return _vm.propagateCustomEvt('agree')}}},[_vm._t("agreeButton",function(){return [_vm._v("Да")]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }