
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import XButton from "./SimpleButton.vue";

@Component({
  components: { XButton },
})
class Slider extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: false }) readonly showPreloader!: boolean;

  @Prop({ default: "" }) readonly containerModClass!: string;
  @Prop({ default: "" }) readonly boxModClass!: string;

  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly subtitle!: string;

  @Watch("show")
  public showChanged() {
    this.$store.commit("dialog/setIsOpen", this.show);
  }
}

export default Slider;
