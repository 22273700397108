
  import {Component, Vue} from "vue-property-decorator";
  import HistoryTabContent from "@/components/for-counters-values/HistoryTabContent.vue";
  import {mapGetters, mapMutations} from "vuex";
  import XSlider from "@/components/SimpleSlider.vue";
  import CounterHistoryTable from "./CounterHistoryTable.vue";
  import InvisibleWall from "@/components/InvisibleWall.vue";

  @Component({
    components: {
      HistoryTabContent,
      XSlider,
      CounterHistoryTable,
      InvisibleWall,
    },
    computed: {
      ...mapGetters({
        sortedPointsByObject: "accountingPoints/sortedPointsByObject",
        pointName: "accountingPoint/devicePointName",
        pointNumber: "accountingPoint/devicePointNumber",
        contractType: "contract/type"
      }),
    },
    methods: {
      ...mapMutations("accountingPoints",
        {
          setUseHistoryList: "setUseHistoryList",
          setUseMeteringPointSorting: "setUseMeteringPointSorting"
        })
    }
  })

  class ValuesHistory extends Vue {
    [x: string]: any;
    showPointDetails = false;
    showWall = false;
    mounted() {
      this.setUseHistoryList(true)
      this.contractType !== 3 && this.setUseMeteringPointSorting(true)
    }
    destroyed() {
      this.setUseHistoryList(false)
      this.setUseMeteringPointSorting(false)
    }
  }

  export default ValuesHistory

