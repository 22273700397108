var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('x-card',{attrs:{"title":"Основная информация"}},[_c('card-row-with-email-form',{attrs:{"show-row":_vm.showEmail},on:{"show":() => {
        _vm.showPassword = false;
        _vm.showEmail = !_vm.showEmail;
      },"show-dialog":function($event){return _vm.$emit($event.name, $event.email)}}}),_c('v-divider',{staticClass:"mb-2"}),_c('card-row-with-password-form',{attrs:{"show-row":_vm.showPassword},on:{"show":() => {
        _vm.showEmail = false;
        _vm.showPassword = !_vm.showPassword;
      },"show-notice":function($event){return _vm.$emit($event.name, $event.message)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }