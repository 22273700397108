
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class CardAsAlert extends Vue {
  @Prop({ required: true }) readonly imageName!: string;

  data() {
    return { imageSize: 160 };
  }
}

export default CardAsAlert;
