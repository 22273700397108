
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { mapGetters, mapState } from "vuex";
import XDialog from "../SimpleDialog.vue";
import XButton from "../SimpleButton.vue";
import { confirmActionForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";

const { values, attrs, errors, validations } = confirmActionForm;

@Component({
  components: { XDialog, XButton },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapState({ user: "user" }),
  },
  validations,
})
class ConfirmActionDialog extends Mixins(AppApiMixin, XDialog) {
  @Prop({ required: true }) readonly showDialog!: boolean;

  @Prop({ default: 3 }) readonly intervalMin!: number;
  @Prop({ default: "" }) readonly payload!: string;

  values = { ...values };
  allFieldAttrs = attrs;

  interval = -1;
  intervalMs = this.intervalMin * 60 * 1000;
  intervalMessage = "";

  useAgreeButtonLoading = false;

  @Watch("showDialog")
  public showDialogChanged() {
    const { showDialog, startTimer, stopTimer } = this;

    if (showDialog) {
      startTimer();
    } else {
      stopTimer();

      this.values.code = "";
      this.$v.$reset();
    }
  }

  public startTimer() {
    const secondAsMs = 1000;
    let passedMs = 0;

    this.interval = setInterval(() => {
      const timeLeft = new Date(this.intervalMs - passedMs);

      this.intervalMessage = [timeLeft.getMinutes(), timeLeft.getSeconds()]
        .map((v) => (v > 9 ? v : `0${v}`))
        .join(":");

      passedMs += secondAsMs;

      if (this.intervalMs === passedMs) {
        this.stopTimer();
      }
    }, secondAsMs);
  }

  public stopTimer() {
    clearInterval(this.interval);
    this.interval = -1;
    this.intervalMessage = "";
  }

  public onRepeatButton() {
    const { changeEmail, payload, startTimer } = this;

    changeEmail(payload)
      .then(() => startTimer())
      .catch(console.error);
  }

  public onAgree(evt: { name: string; payload: null }) {
    this.useAgreeButtonLoading = true;

    const {
      confirmEmailChange,
      payload: email,
      propagateCustomEvt,
      setUser,
      values,
      user,
    } = this;

    confirmEmailChange({ email, code: values.code })
      .then((_) => {
        setUser({ ...user.credentials, email });
        propagateCustomEvt(evt.name);
      })
      .catch(console.error)
      .finally(() => {
        this.useAgreeButtonLoading = false;
      });
  }
}

export default ConfirmActionDialog;
