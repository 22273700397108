
import { Component, Mixins, Emit, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import SimpleButton from "@/components/SimpleButton.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import DropZone from "@/components/for-counters-values/DropZone.vue";
import ListItem from "@/components/for-counters-values/ListItem.vue";
// import Progress from "@/components/for-counters-values/Progress.vue";
import { AxiosError, AxiosResponse } from "axios";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";
import SimpleCardWithTable from "@/components/SimpleCardWithTable.vue";
import { valueOrDash } from "@/assets/scripts/utils";
import XNotice from "@/components/hoc/SimpleNotice.vue";

@Component({
  components: {
    SimpleButton,
    DropZone,
    ListItem,
    // Progress,
    SimpleCardWithTable,
    XNotice,
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      contractId: "contract/id",
    }),
  },
})
class MassValuesInput extends Mixins(AppApiMixin, DocGetterMixin) {
  [x: string]: any;

  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: false }) readonly closeSlider!: () => void;

  step = 1;
  downloadDone = false; // флаг скачен ли файл
  downloadButtonLoading = false;
  checkingErrorsHeaders = [
    "Договор",
    "Точка учета",
    "Прибор учета",
    "Тип",
    "Ошибка",
  ];
  checkingErrors = [];
  isCheckingErrors = false;
  step4message = "Выполнено";
  useSuccessAlert = false;

  private files: File[] = [];
  private uploading = false;
  private successfullUploaded = false;
  private successfullChecked = false;
  private resultData: { [x: string]: string } | null = null;

  @Watch("show")
  public onSliderVisibilityChange(nextState: boolean) {
    if (!nextState) {
      this.step = 1;
      this.downloadDone = false;
      this.downloadButtonLoading = false;
      this.checkingErrors = [];
      this.isCheckingErrors = false;
      this.successfullUploaded = false;
      this.successfullChecked = false;
      this.useSuccessAlert = false;
      this.resultData = null;
      this.files = [];
    }
  }

  onFilesAdded(files: File[]) {
    this.files = [...files];
  }

  clearFileList() {
    this.files = [];
    this.successfullUploaded = false;
  }

  goStepBack() {
    this.clearFileList();
    this.step--;
  }

  goNextStep() {
    this.step++;
  }

  uploadToServer() {
    this.uploading = true;

    let formData = new FormData();
    formData.append("file", this.files[0]);
    formData.append("contractId", this.contractId);

    const response: Promise<any> =
      this.step === 3
        ? this.checkMassValuesInputTemplateByContract(formData)
        : this.sendMassValuesInputTemplateByContract(formData);

    response
      .then((res: AxiosResponse) => {
        if (this.step === 3) {
          this.checkingErrors = res.data.data
            ? res.data.data.map((item: any) => [
                valueOrDash(item.договор),
                valueOrDash(item.лицевой),
                valueOrDash(item.счетчик),
                valueOrDash(item.тип),
                valueOrDash(item.ошибка),
              ])
            : [];

          if (!!this.checkingErrors.length) this.isCheckingErrors = true;
          else this.successfullChecked = true;
          this.goNextStep();
        } else if (this.step === 4) {
          this.isCheckingErrors = false;
          this.successfullUploaded = true;
          this.useSuccessAlert = true;
          this.files = [];
          if (res.data.data) {
            this.resultData = res.data.data;
          }
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  public async downloadResults() {
    this.downloadButtonLoading = true;
    try {
      if (
        this.resultData &&
        this.resultData.fileId &&
        this.resultData.fileName &&
        this.resultData.fileUrl
      ) {
        const response = await this.getMassValuesInputResultFile({
          fileId: this.resultData.fileId,
          fileName: this.resultData.fileName,
          fileUrl: this.resultData.fileUrl,
          download: true,
        });
        this.downloadDocument(response);
        this.downloadDone = true;
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      this.downloadButtonLoading = false;
    }
  }

  @Emit("click")
  public downloadFile(): void {
    this.downloadButtonLoading = true;

    const data = {
      contractId: this.contractId,
      download: true,
    };
    this.getMassValuesInputTemplateByContract(data)
      .then((response: AxiosResponse) => {
        this.downloadDocument(response);
        this.downloadDone = true;
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        this.downloadButtonLoading = false;
      });
  }
}

export default MassValuesInput;
