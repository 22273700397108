
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import ExpansionBarWithForm from "./ExpansionBarWithForm.vue";
import XForm from "../SimpleForm.vue";
import { passwordReplacementForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";

const { values, attrs, validations, errors } = passwordReplacementForm;

@Component({
  components: { ExpansionBarWithForm },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapState({
      user: "user"
    }),
    ...mapGetters("user", {
      userId: "id"
    }),
    ...mapGetters('appConfig', {
      appConfigDemo: 'demo',
    }),
  },
  validations,
})
class CardRowWithPasswordForm extends Mixins(AppApiMixin, XForm) {
  @Prop({ default: false }) readonly showRow!: boolean;

  values = values;
  allFieldAttrs = attrs;

  showPasswords: { [fieldName: string]: boolean } = Object.keys(
    this.values
  ).reduce((acc, key) => ({ ...acc, [key]: false }), {});

  useSubmitButtonLoading = false;

  @Watch("showRow")
  public showChanged(): void {
    if (!this.showRow) {
      this.resetValues();
      this.resetPasswordFieldStates();
    }
  }

  private resetPasswordFieldStates(): void {
    const fieldNames = Object.keys(this.values);

    fieldNames.forEach((fieldName: string) => {
      this.showPasswords[fieldName] = false;
    });
  }

  public submitForm(): void {
    this.useSubmitButtonLoading = true;

    const {
      changePassword,
      getValues,
      resetPasswordFieldStates,
      resetValues,
      setUser,
      user,
      userId,
    } = this;

    const { oldPwd, newPwd } = getValues();

    changePassword({ userId, oldPwd, newPwd })
      .then((_) => {
        const evtName = "show-notice";

        this.$emit(evtName, {
          name: evtName,
          message: "Пароль успешно изменен",
        });

        setUser({ ...user.credentials, ["требуетсясменапароля"]: false });

        resetValues();
        resetPasswordFieldStates();
      })
      .catch(console.error)
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default CardRowWithPasswordForm;
