
import { Component, Prop, Watch } from "vue-property-decorator";
import XDropdown from "../SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class DropdownWithRadioButtons extends XDropdown {
  @Prop({ required: true }) readonly activatorText!: string;

  @Prop({ default: 0 }) readonly startActiveRadioIndex!: number;
  @Prop({ default: false }) readonly resetOnClose!: boolean;

  chosenRadioIndex = this.startActiveRadioIndex;
  show = false;

  @Watch("show")
  public showChanged() {
    if (this.resetOnClose) {
      this.chosenRadioIndex = this.startActiveRadioIndex;
    }

    this.$emit("update:show", this.show);
  }

  public toggleShow(show: boolean) {
    this.show = show;
  }

  public log(...args: any[]) {
    console.log(...args)
  }
}

export default DropdownWithRadioButtons;
