
import { Component, Vue } from "vue-property-decorator";
import CardAsAlert from "../hoc/CardAsAlert.component.vue";

@Component({
  components: { CardAsAlert },
})
class WelcomeCard extends Vue {}

export default WelcomeCard;
